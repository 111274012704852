import './App.css';

function App() {
  return (
    <div className="placeholder-wrap">
      <img src="Brooklyn_Bridge_Header_Red.png" alt="valentine lux portrait" />
    </div>
  );
}

export default App;
